import React, { useEffect, useState } from 'react';

const GoogleTable = () => {
  const [totalProfit, setTotalProfit] = useState(0);
  const [profitcnt, setProfitcnt] = useState(0);
  const [totalLoss, setTotalLoss] = useState(0);
  const [losscnt, setLosscnt] = useState(0);
  const [profitratio, setProfitratio] = useState(0);

  useEffect(() => {
    const drawTable = async () => {
      console.log("start");
      try {
        // const response = await fetch('http://localhost:4000/history.json');
        const response = await fetch('history.json');
        if (!response.ok) {
          console.log(" error ");
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

         // 데이터를 날짜 기준으로 내림차순 정렬합니다.
        data.sort((a, b) => new Date(b.day) - new Date(a.day));
        
        // Load the charts library
        window.google.charts.load('current', { packages: ['table'] });
        window.google.charts.setOnLoadCallback(() => {
          console.log(" onload ");
          var tableData = new window.google.visualization.DataTable();
          console.log("tableData : ", tableData);
          tableData.addColumn('string', '날짜 / 종목');
          tableData.addColumn('number', '최대 수익률 (%)');

          let _totalProfit = 0;
          let _profitcnt = 0;
          let _totalLoss = 0;
          let _losscnt = 0;

          data.forEach(item => {
            const profitRate = parseFloat(item.maxprofit).toFixed(2);
            if (item.maxprofit > 0) {
              _totalProfit += parseFloat(item.maxprofit);
              _profitcnt += 1;
            } else {
              _totalLoss += parseFloat(item.maxprofit);
              _losscnt += 1;
            }
            tableData.addRow([`${item.day} / ${item.jongmok}`, parseFloat(profitRate)]);
          });

          let _profitratio = _profitcnt / (_losscnt + _profitcnt) * 100;

          setTotalProfit(_totalProfit);
          setProfitcnt(_profitcnt);
          setTotalLoss(_totalLoss);
          setLosscnt(_losscnt);
          setProfitratio(_profitratio);

          var table = new window.google.visualization.Table(document.getElementById('table_div'));
          table.draw(tableData, { showRowNumber: true, width: '100%', height: '100%' });
        });
      } catch (error) {
        console.error('테이블을 그리는데 실패했습니다:', error);
      }
    };

    drawTable();
  }, []);

  return (
    <div>
      <div id="profit">익절: {totalProfit.toFixed(2)}</div>
      <div id="profitcnt">익절 수: {profitcnt}</div>
      <div id="loss">손절: {totalLoss.toFixed(2)}</div>
      <div id="losscnt">손절 수: {losscnt}</div>
      <div id="ratio" style={{ margin: 0 }}>성공률: {profitratio.toFixed(2)}%</div>
      <div id="table_div" style={{ width: '100%', height: '500px', overflowX: 'auto', overflowY: 'auto', marginTop: 0, margin: 0 }}>
      </div>
    </div>
  );
};

export default GoogleTable;
