import React, { useState, useEffect } from 'react'; // useState와 useEffect를 import합니다.
import logo from './logo.png'; // logo를 import합니다.
import './App.css';
// import StockChart from './StockChart';
import GoogleTable from './GoogleTable';

function App() {
  const [showLogo, setShowLogo] = useState(true); // showLogo 상태를 true로 초기화합니다.

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogo(false); // 2초 후 showLogo 상태를 false로 설정합니다.
    }, 2000); // 2000밀리초(2초) 후 함수를 실행합니다.

    return () => clearTimeout(timer); // 컴포넌트가 언마운트될 때 타이머를 정리합니다.
  }, []); // 빈 배열은 이 효과가 컴포넌트가 마운트될 때 단 한 번만 실행되어야 함을 의미합니다.

  return (
    <div className="App">
      {showLogo ? (
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          {/* <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a> */}
        </header>
      ) : (
        <GoogleTable /> // showLogo가 false일 때 GoogleTable 컴포넌트를 렌더링합니다.
      )}
    </div>
  );
}

export default App;
